// http://ionicframework.com/docs/v2/theming/
// App Global Sass
// --------------------------------------------------

//@import '../global';
@import '../mixin';
@import '../theme/variables';
//@import '../assets/styles/ionicons.min.css';

body.control-panel {
  //noinspection CssInvalidFunction
  margin-top: env(safe-area-inset-top);
  background-color: $theme-light-bg-color;
  opacity: 1;
}

ion-app {
  background-color: $theme-light-bg-color;
  opacity: 1;
  font-size: 12px;

  &.ios {
    font-family: "Sofia Pro", "Helvetica Neue", sans-serif;
    font-size: 14px;
  }

  &.md {
    font-family: "Sofia Pro", "Helvetica Neue", sans-serif;
    font-size: 14px;
  }

  &.wp {
    font-family: "Sofia Pro", "Noto Sans", sans-serif;
    font-size: 12px;
  }

  h2 {
    color: $theme-light-font-color;
    font-size: 1.8em;
    margin-top: 0;
    margin-bottom: 1em;

    span {
      margin: 0 1em;
    }

    ion-icon {
      position: relative;
      font-weight: bold;
      top: .1em;
    }
  }

  h3 {
    font-size: 1.3em;
    margin-top: 0;
    margin-bottom: 1em;
    color: $theme-light-font-color;
  }

  h4 {
    font-size: 1.1em;
  }

  p {
    &.tight {
      margin: 0 0 .2em 0;
    }
  }
}


// Replace "padding" in ion-content with class="ion-padding" to respect header padding
ion-content {
  div.ion-padding {
    padding: 0;
    margin-left: $header-inset;
    margin-right: $header-inset;
  }

  &[padding] {
    div.ion-padding {
      margin-left: $header-inset - 18px;
      margin-right: $header-inset - 18px;
    }
  }
}

div.light {
  background-color: $theme-light-bg-color;
}

a, p {
  color: $theme-light-font-color;
}

hr {
  background-color: $theme-light-border-color;
  height: 2px;
}

ion-button {
  box-shadow: none;
  outline: none;

  &.clics, &.button-default {
    overflow: hidden;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 14px;
    height: 26px;
    border-radius: 16px;
    color: $theme-light-font-color;
    --background: $theme-light-bg-color;
    --background-hover: $theme-light-bg-color;
    --box-shadow: none;
    border: $theme-light-font-color 1px solid;

    &.danger {
      --color: #aa0000;
    }
  }

  &.icon-only {
    border: none;
    border-radius: 0;
  }

  &.right {
    float: right;
  }

  &[role="checkbox"] {
    border: none;
  }

  &.item-cover {
    background: none;
  }
}

div.alert-button-group {
  background-color: $theme-light-alert-bg;
  padding: 6px;
}

ion-toggle {
  --background-checked: #F9BBC6;
  --background: #c1c1c1;
  --handle-background: #FFFFFF;
  --handle-background-checked: #ffffff;
}

ion-label {
  text-align: left;
  font-size: 1.2em;
  padding: 0;
  color: $label_color;
}

ion-item {
  padding: 0;

  &.item-has-focus {
    ion-label {
      color: $theme-light-font-color !important;
    }
  }

  &.item-radio-checked, &.item-checkbox-checked {
    ion-label {
      --color: #C0365f;
      text-decoration: none;
    }

    ion-radio {
      --color: $theme-light-border-color;
      --color-checked: #F9BBC6;
    }
  }
}

ion-checkbox {
  --background: $theme-light-bg-color;
  --border-color: #cccccc;
  --background-checked: $theme-light-bg-color;
  --checkmark-color: #C0365f;
  --border-color-checked: #C0365f;
}

ion-action-sheet {
  div.action-sheet-title {
    font-size: $action-sheet-font-size;
    font-weight: bold;
    text-transform: uppercase;
  }

  div.action-sheet-group {
    ion-button {
      color: $theme-light-font-color;
      font-size: $action-sheet-font-size;
      border: none;
    }
  }
}

.clickable {
  cursor: pointer;
}

.selected {
  color: $theme-light-active-color;
}

.addable {
  cursor: pointer;
}

ion-icon {
  color: $theme-light-font-color;

  &.rating5 {
    color: purple;
  }

  &.rating4 {
    color: gold;
  }

  &.rating3 {
    color: green;
  }

  &.rating2 {
    color: orangered;
  }

  &.rating1 {
    color: maroon;
  }

  &.rating0 {
    color: $theme-light-icon-color;
  }
}


div.title {
  font-size: 1.4em;
  color: $theme-light-font-color;
  height: 1em;
}

ion-footer {
  background-color: $theme-light-bg-color;
  //noinspection CssInvalidFunction
  padding-bottom: env(safe-area-inset-top);
  //noinspection CssInvalidFunction
  margin-bottom: env(safe-area-inset-bottom);
}

ion-header {
  background-color: $theme-light-bg-color;

  &.naked {
    padding-top: 1em;
  }

  ion-toolbar {
    --padding-start: 16px;
    ion-title {
      text-transform: uppercase;
      span {
        text-transform: none;
      }
    }
    .toolbar-background-ios {
      background-color: inherit;
    }
  }

  ion-button.button-clear {
    font-size: 20px;
  }

  ion-button.bar-button {
    position: relative;
    font-size: 30px;
    right: 10px;
  }
}

ion-item {
  padding: 0;
}

ion-reorder {
  ion-icon {
    color: $theme-light-icon-color !important;
  }
}

ion-input, ion-textarea {
  background-color: $theme-light-input-bg-color;
  color: $theme-light-input-text-color;
  border: $theme-light-border-color 1px solid;
  --placeholder-color: #666666;
  font-size: 20px;
  padding: 0 1em;
}

ion-modal {
  --width: 100% !important;
  --height: 100% !important;

  .modal-wrapper {
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    height: 100%;
  }
}

// NOTE: this is only used on the scan page and is a vestige of an earlier layout
div.ion-content {
  position: relative;
  display: block;
  color: #000;
  background-color: #fff;
  outline: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  contain: layout size style;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -moz-box-sizing: border-box;
  font-size: 1.4rem;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.42857143;

  &.with-header {
    margin-top: 70px;
  }

  &.with-navigation {
    margin-top: 92px;
  }

  &.padding {
    padding: 16px;
  }
}

ion-textarea {
  textarea {
    padding: 6px;
  }
}

ion-range {
  --bar-background: red;
  --knob-border-radius: 10%;
  --knob-size: 5px;
}

ion-alert {
  div.alert-wrapper {
    background-color: $theme-light-alert-bg;

    h2 {
      color: $special-text-color;
    }

    div.alert-message {
      color: $theme-light-font-color;
    }
  }

  div.alert-button-group {
    background-color: $theme-light-alert-bg;

    ion-button.alert-button {
      background-color: $theme-light-input-bg-color;
      border-radius: 40px;
      color: $theme-light-font-color;
      margin-bottom: 6px;
    }
  }
}

div.container {
  width: 86%;
  margin-top: 0;
  padding: 0;
}

div.client_content {
  position: relative;
}

div.client-notes {
  height: 1.4em;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 11px;
  font-size: .8em;

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

div#app_main {
  position: relative;
  width: 100%;
  height: $main-content-height;
  margin: 0;
  background-color: $theme-light-bg-color;

  &.fullscreen {
    margin: 1% 0 0 0;
  }

  ion-nav {
    width: 100% !important;
    margin-top: 1.3%;
    margin-left: 2%;
    position: relative;
    display: inline-block;
    //width: $main-content-width;
    &.fullscreen {
      width: 100%;
    }
  }
}

nav.navbar {
  div.container {
    width: 100%;
  }

  a.navbar-logo {
    position: relative;
    left: 0;
  }
}

client-header {
  margin-bottom: 12px;
}

div#color_select {
  width: 100%;
}

div#color_select_page {
  position: relative;
  width: 80%;
  margin: 0.5em 0;

  div.action_icons {
    color: $theme-light-font-color;
    font-size: 30px;
    position: absolute;
    bottom: -80px;
    right: 8%;
    text-align: right;
    z-index: 100;

    div.action_icon {
      display: inline-block;
      width: auto;
      text-align: center;
      cursor: pointer;
      margin-right: 0.6em;

      p {
        position: relative;
        top: -0.4em;
        font-size: 0.3em;
        margin: 0;
        padding: 0;
      }
    }
  }
}

div.user_signon_panel {
  padding: 1.5em;
  margin: 0 0 0.8em 0;
  cursor: pointer;

  &:hover {
    background-color: #00ADF0;
    color: #ffffff;
  }
}

div.client_mini_panel {
  cursor: pointer;
  margin-bottom: 2em;

  div.headshot {
    display: inline-block;
    width: 100px;
    height: 100px;
    vertical-align: top;
    margin-right: 1em;

    img {
      width: 100%;
    }
  }

  div.info {
    display: inline-block;
    padding-top: 1em;

    h2 {
      margin: 0 0 .1em 0;
    }
  }
}

div.client_detail_panel {
  position: relative;

  img {
    display: inline-block;
    width: 200px;
  }

  div.info {
    display: inline-block;

    p {
      font-size: 1.0em;
      margin-bottom: .5em;
    }
  }

  div.actions {
    position: absolute;
    top: 31%;
    right: 15%;

    a {
      outline: none;

      &:hover {
        text-decoration: none;
        color: #00ADF0;
      }
    }
  }

  p {
    font-size: 2em;

    &.ready_message {
      color: #00ADF0;
    }
  }
}

div#palette_select {
  margin-top: 20px;

  div.chiclet {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
  }

}

div#color_dispense_list {
  position: relative;
  padding-bottom: 120px;

  div#dispense_amount_presets {
    margin: 30px 0;

    ion-button.preset {
      margin-right: 20px;
      padding: 8px 10px;
      background: none;
      border: #d0d0d0 1px solid;

      &.selected {
        background-color: #00AEF6;
        color: #ffffff;
      }
    }
  }

  div.color_measure {
    margin-bottom: 30px;

    div.color_swatch {
      display: inline-block;
      margin-right: 30px;
      height: 80px;
      width: 80px;

      img {
        width: 100%;
      }
    }

    div.weight_entry {
      display: inline-block;
      vertical-align: top;
      padding: 27px 0 0 0;
      height: 60px;

      div.amount-string {
        display: inline-block;
        width: 300px;
      }

      div.weight_form {
        padding-left: 30px;
        display: inline-block;

        input[type~='text'] {
          width: 50px;
        }

        label {
          font-weight: normal;
          text-transform: lowercase;
        }
      }
    }
  }

  div.action_icons {
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 300px;
    text-align: right;
    z-index: 100;

    div.action_icon {
      cursor: pointer;
      font-size: 40px;
      display: inline-block;
      width: 70px;
      text-align: center;
    }
  }
}

// In the color library a color item is a single formula "chiclet", with text overlay
div.color_item {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 140px;
  margin: 1%;
  cursor: pointer;

  &.list {
    display: block;
    margin: 0 auto;
    height: 160px;

    img {
      display: block;
      height: 130px;
    }

    p {
      color: #0f0f0f;
    }
  }

  div.color_swatch {
    width: 80%;
    height: 110px;
    position: absolute;
    right: 0;
    top: 0;
    //outline: $theme-light-icon-color 4px solid;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    p.title {
      position: absolute;
      right: 1%;
      bottom: -4px;
      width: 98%;
      opacity: .3;
      text-shadow: black 1px 1px;
      line-height: 1em;
      color: #ffffff;
      text-align: center;
    }
  }

  &.selected {
    div.color_swatch {
      outline: #00ADF0 6px solid;
    }

    div.select_icon {
      color: #000000;
      background: none;
    }
  }

  div.select_icon {
    background: none;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 4%;
    color: #cccccc;

    i {
      font-size: 1em;
    }
  }
}

// Text link menu at the bottom of pages such as Lab ("Swatch Go Use Reset")
div.bottom_action {
  display: block;
  position: relative;
  border: none;
  width: 104%;
  margin-left: -2%;
  height: $bottom-action-height - 4px;
  background-color: $theme-light-header-bg;
  color: $theme-light-font-color;
  text-align: center;
  z-index: 200;
  ion-grid {
    position: relative;
    top: -4px;
    ion-row {
      position: relative;
      top: -5px;
      color: $theme-light-font-color;
      height: $bottom-action-row-height + 2px;
      overflow: hidden;
      border: none;
      ion-col {
        display: inline-block;
        position: relative;
        padding-top: 6px;
        width: auto;
        font-size: 14px;
        color: $theme-light-nav-font-color;
        text-transform: uppercase;
        cursor: pointer;

        clics-icon {
          position: relative;
          top: -6px;
        }

        &.disabled {
          color: $theme-light-disabled-color;
          cursor: default;

          clics-icon {
            cursor: default;
          }

          &:hover {
            color: $theme-light-disabled-color;
          }

          a {
            color: $theme-light-disabled-color;
            cursor: default;

            &:hover {
              color: $theme-light-disabled-color;
            }
          }
        }
      }

      &:last-child {
        background-color: $theme-light-nav-bg;

        ion-col {
          padding-top: 5px;
          color: white;
          &.disabled {
            color: $theme-light-alt-disabled-color;
            cursor: default;
          }

          clics-icon {
            top: -4px;
          }
        }
      }
    }
  }

  &.no_border {
    border-bottom: none;
  }

  &.single {
    height: $bottom-action-height-single;
  }

  .clickable {
    cursor: pointer;
  }
}

.table-view {
  div.key {
    display: inline-block;
    margin-left: 5px;
    margin-right: .4em;
    font-size: 1.4em;
    font-weight: bold;
    color: $theme-light-active-color;
  }

  div.value {
    display: inline-block;
    font-size: 1.4em;
    font-weight: normal;
    color: $theme-light-font-color;
  }
}

// List of text links on a page, typically to other pages
ul.link-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 0.5em;
    cursor: pointer;

    &.active {
      color: $theme-light-active-color;
    }
  }
}

.mini_link {
  display: inline-block;
  font-size: 12px;
}

.clics-toast {
  > div {
    background-color: $theme-light-nav-bg;
    color: white;
    text-align: center;
    font-size: 1.2em;
    font-weight: normal;
  }

  &.error {
    > div {
      background-color: darkred !important;
    }
  }

  &.rinse {
    > div {
      color: #7399c4 !important;
    }
  }
}

input.android {
  padding: .2em .5em;
  color: $theme-light-font-color;
}

span.dev-icon {
  display: inline-block;
  position: relative;
  top: -0.1em;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  background-color: $theme-light-icon-color;
  border-radius: 2em;
  padding: 0.1em 0.4em 0 0.4em;
  min-width: 3em;
  text-align: center;
}

span.coverage {
  margin-left: 0.2em;
}


// Dark theme translations dark mode ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
body.control-panel.dark {
  background-color: $theme-dark-bg-color;

  ion-app {
    background-color: $theme-dark-bg-color;
  }

  ion-header {
    padding: 0 !important;
    height: auto !important;
    background: #000000;
  }

  ion-title {
    .toolbar-title, .toolbar-title-ios, .toolbar-title-md {
      padding: 0 !important;
      height: auto !important;
      color: $theme-dark-font-color !important;
    }
  }

  h2 {
    color: $theme-dark-active-color;
  }

  h3 {
    color: $theme-dark-font-color;
  }

  hr {
    background-color: $theme-dark-border-color;
  }

  div.title {
    color: $theme-dark-font-color;
  }

  a, p {
    color: $theme-dark-font-color;
  }

  ion-content {
    --background: #000000;
    --color: #c7c7c7;

    div.scroll-content {
      background-color: inherit;
    }
  }

  form {
    ion-item::part(native) {
      background: $theme-dark-bg-color;
    }
  }

  ion-button.clics, ion-button.button-default {
    border: white 1px solid;
    background-color: $theme-dark-bg-color;
    color: white;
  }

  ion-button.no-border {
    --border-style: none;
    border: none;
  }

  ion-toolbar, ion-footer {
    background-color: $theme-dark-bg-color;
    ion-button {
      ion-icon {
        color: $theme-dark-font-color;
      }
    }
  }

  ion-icon {
    color: $theme-dark-font-color;

    &.rating0 {
      color: $theme-light-icon-color;
    }
  }
  ion-toggle {
    --background-checked: #F9BBC6;
    --background: #666666;
    --handle-background: #FFFFFF;
    --handle-background-checked: #ffffff;
  }

  ion-list {
    background: $theme-dark-bg-color;

    ion-item::part(native) {
      background: $theme-dark-bg-color;
      color: $theme-dark-font-color;
    }
  }

  ion-checkbox {
    --border-color: #666666;
    --background: #000000;
    --border-color-checked: #F9BBC6;
    --checkmark-color: #F9BBC6;
  }

  ion-item::part(native) {
    background: $theme-dark-bg-color;
  }

  .selected {
    color: $theme-dark-active-color;
  }

  ion-alert {
    h2 {
      text-transform: uppercase;
    }

    div.alert-wrapper {
      background-color: $theme-dark-alert-bg;

      div.alert-message {
        color: $theme-dark-font-color !important;
      }

      div.alert-radio-group {
        div.alert-radio-label {
          color: $theme-dark-font-color;
        }

        ion-button[aria-checked="true"] {
          div.alert-radio-inner {
            border-color: $special-text-color;
          }

          div.alert-radio-label {
            color: $special-text-color;
          }
        }
      }
    }

    div.alert-button-group {
      background-color: $theme-dark-alert-bg;

      ion-button[ion-button="alert-button"] {
        border-style: none;
        background-color: $theme-dark-alert-button;
        color: $theme-dark-font-color;
      }
    }
  }

  ion-alert.warning {
    h2 {
      color: #aa0000;
    }
    warn{
      color: #aa0000;
    }
    number {
      font-size: medium;
      font-weight: bold;
      color: #aa0000;
    }
    .alert-button{
      font-weight: normal;
    }
    
    div.alert-button-group {
    background-color: $theme-dark-alert-bg;
    font-weight: normal;
      ion-button[ion-button="alert-button"] {
        border-style: none;
      }
    }
    
  }

  div.range-bar {
    background-color: $theme-dark-active-color;
  }

  div.range-knob-handle {
    div.range-knob {
      background-color: $theme-dark-border-color;
    }
  }

  ion-item {
    ion-label {
      --color: #c7c7c7;
    }

    &.item-has-focus {
      ion-label {
        color: $theme-dark-font-color !important;
      }
    }
  }

  input[type="text"], input[type="email"], input[type="password"], textarea {
    background-color: $theme-dark-input-bg-color;
    color: $theme-dark-input-text-color;
    border: none;
    outline: none;

    &::placeholder {
      color: $theme-dark-disabled-color;
    }
  }

  ion-input, ion-textarea {
    --placeholder-color: #888888;
    background-color: $theme-dark-input-bg-color;
    color: $theme-dark-input-text-color;
  }

  ion-searchbar {
    ion-button.button-clear {
      border: none;
      background: none;

      span.button-inner {
        color: white !important;
      }
    }
  }

  ion-textarea {
    background-color: $theme-dark-bg-color;
    color: $theme-dark-input-text-color;

    textarea {
      border: $theme-dark-border-color 1px solid;
      color: $theme-dark-input-text-color !important;
    }
  }

  ion-item {
    padding: 0;
    &.item-radio-checked, &.item-checkbox-checked {
      ion-label {
        color: #F9BBC6;
      }
      ion-radio {
        --color: #F9BBC6;
        --color-checked: #F9BBC6;
      }
    }

  }

  ion-item::part(native) {
    &.item-ios {
      background-color: inherit;
    }
  }

  ion-footer {
    background-color: $theme-dark-bg-color;
  }

  ion-action-sheet {
    --color: #f0f0f0;
    --button-color: #c7c7c7;
    --button-color-activated: #c7c7c7;
    --button-color-focused: #c7c7c7;
    --background: #333333;
  }

  ion-reorder {
    ion-icon {
      color: $theme-dark-icon-color !important;
    }
  }

  div#client-name {
    //border-bottom: $theme-dark-border-color 1px solid;
    color: $theme-dark-active-color;
  }

  div#app_main {
    background-color: $theme-dark-bg-color;
  }

  div#color_select_page {
    div.action_icons {
      color: $theme-dark-font-color;
    }
  }

  :host {
    div.bottom_action {
      height: $bottom-action-height;
      background-color: $theme-dark-bg-color;
      color: $theme-dark-font-color;
      ion-grid {
        background-color: $theme-dark-bg-color;
        color: $theme-dark-font-color;
        ion-row {
          background: $theme-dark-header-bg;
          color: $theme-dark-nav-font-color;
          ion-col {
            background: $theme-dark-header-bg;
            color: $theme-dark-nav-font-color;
            &.disabled {
              color: $theme-dark-disabled-color;
            }
            a {
              color: $theme-dark-nav-font-color;
              &.disabled {
                cursor: pointer;
                color: $theme-dark-disabled-color;
                &:hover {
                  color: $theme-dark-disabled-color;
                }
              }
            }

            &.disabled {
              color: $theme-dark-disabled-color;
              &:hover {
                color: $theme-dark-disabled-color;
              }
              a {
                color: $theme-dark-disabled-color;
                &:hover {
                  color: $theme-dark-disabled-color;
                }
              }
            }
          }

          &:last-of-type {
            background: $theme-dark-nav-bg !important;
            color: $theme-dark-nav-font-color;
            ion-col {
              background-color: $theme-dark-nav-bg !important;
            }
          }
        }
      }

      &.single {
        height: $bottom-action-height-single;
      }
    }
  }


  ul.link-list {
    li {
      &.active {
        color: $theme-dark-active-color;
      }
    }
  }

  .clics-toast {
    > div {
      background-color: $theme-dark-nav-bg;
      color: white;
    }

    &.error {
      > div {
        color: darkred !important;
      }
    }

    &.rinse {
      > div {
        color: #7399c4 !important;
      }
    }
  }

  .table-view {
    div.key {
      color: $theme-dark-active-color;
    }

    div.value {
      color: $theme-dark-font-color;
    }
  }

  span.dev-icon {
    background-color: $theme-dark-font-color;
    color: black;
  }

  // --- modal wrapper ---------------------------------------

  .modal-wrapper {
    color: $theme-dark-font-color;
    background-color: $theme-dark-bg-color;

    ion-header {
      padding: 0 !important;
      height: auto !important;
      background-color: $theme-dark-bg-color;
    }


    ion-list {
      background-color: $theme-dark-bg-color;

      ion-item::part(native) {
        background-color: inherit;
      }
    }

    form {
      ion-item::part(native) {
        background: $theme-dark-bg-color;
      }
    }

    ion-searchbar {
      background-color: $theme-dark-bg-color;

      ion-button.button-clear {
        border: none;
        background: none;

        span.button-inner {
          color: white !important;
        }
      }
    }

    input {
      background-color: $theme-dark-input-bg-color;
      color: $theme-dark-input-text-color;
    }

    ion-header, ion-toolbar, ion-footer, ion-title, ion-content, div.ion-content, div.modal-wrapper, div#footer_buttons {
      background-color: $theme-dark-bg-color !important;
      color: $theme-dark-font-color;

      ion-icon {
        color: $theme-dark-font-color;
      }
    }

    p {
      color: $theme-dark-font-color;
    }

    ion-title {
      .toolbar-title, .toolbar-title-ios, .toolbar-title-md {
        color: $theme-dark-font-color !important;
      }
    }

    ion-radio {
      color: $theme-dark-font-color !important;

      ion-button {
        color: $theme-dark-font-color !important;
      }
    }

    page-footer {
      background-color: $theme-dark-bg-color !important;

      div#footer_buttons {
        ion-buttons {
          background-color: $theme-dark-bg-color !important;

          ion-button {
            background-color: $theme-dark-bg-color !important;
          }
        }
      }
    }
  }
}

// end Dark theme translations


//~~~~~~~~~~~~~~~~~~~~~ MEDIA ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

@media (max-width: 360px) {
  body.control-panel {
    ion-content {
      div.ion-padding {
        padding: 0;
        margin-left: $header-inset-narrow;
        margin-right: $header-inset-narrow;
      }

      &[padding] {
        div.ion-padding {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
